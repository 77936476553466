export async function renderForm(elId, formId, options) {
    if (!window) return;

    const scriptId = 'plumsail-forms-widget';
    const createForm = function () {
        window.Plumsail.Form.baseUrl = 'https://forms-prod.plumsail.com';
        return new window.Plumsail.Form(elId, formId, options);
    };

    if (document.getElementById(scriptId)) {
        return createForm();
    } else {
        return new Promise(resolve => {
            const script = document.createElement('script');
            script.id = scriptId;
            script.src = 'https://forms-prod.plumsail.com/widget/1.0.8/app.js';
            script.async = true;
            script.onload = function () {
                resolve(createForm());
            };
            document.getElementsByTagName('head')[0].appendChild(script);
        });
    }
}