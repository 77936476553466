import React, { Component } from 'react';
import starIcon from '../../assets/images/star-icon.png'
import contact from '../../assets/images/contact.png'
import { renderForm } from '../../utils/form';

export default class ContactForm extends Component {

    componentDidMount() {
        renderForm('#plumsail-form', '1ffaa824-4f6a-493c-8086-c4ccac5c879e');
    }

    render() {
        return (
            <section className="contact-area pb-100">
                <div className="container">
                    <div className="section-title">
                        <span className="sub-title">
                            <img src={starIcon} alt="contact" />
                            Get in Touch
                        </span>
                        <h2>Ready to get started?</h2>
                        <p>Your email address will not be published. Required fields are marked *</p>
                    </div>
    
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="contact-image">
                                <img src={contact} alt="contact" />
                            </div>
                        </div>
    
                        <div className="col-lg-6 col-md-12">
                            <div className="contact-form">
                                <div id='plumsail-form'>
                                    <div class='fd-form-loading' />
                                </div>
                                {/* <form id="contactForm">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <input type="text" name="name" className="form-control" required placeholder="Your name" />
                                            </div>
                                        </div>
    
                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <input type="email" name="email" className="form-control" required placeholder="Your email address" />
                                            </div>
                                        </div>
    
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <input type="text" name="phone_number" className="form-control" required placeholder="Your phone number" />
                                            </div>
                                        </div>
    
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <textarea name="message" className="form-control" cols="30" rows="6" required placeholder="Write your message..." />
                                            </div>
                                        </div>
    
                                        <div className="col-lg-12 col-md-12">
                                            <button type="submit" className="default-btn">
                                                <i className="flaticon-tick"></i>
                                                Send Message <span></span>
                                            </button>
                                        </div>
                                    </div>
                                </form> */}
                            </div>
                            {/* <div className="contact-form" dangerouslySetInnerHTML={{__html: `
                                    <script type="text/javascript" src="https://forms.plumsail.com/api/widget/1ffaa824-4f6a-493c-8086-c4ccac5c879e"></script>
                                    <div id="plumsail-form-9mo4"><div class="fd-form-loading"></div></div>
                                    <script type="text/javascript">
                                        var fd = new Plumsail.Form("#plumsail-form-9mo4", "1ffaa824-4f6a-493c-8086-c4ccac5c879e")
                                    </script>
                                `}}>                            
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        )

    }

}

// const ContactForm = () => {
    
// }

// export default ContactForm